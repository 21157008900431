import { connect } from "react-redux";
import FidelityCardNumberInput from "app/pages/.shared/FidelityCardNumberInput";
import { getFlightInboundAirlineCode } from "app/pages/Booking/bookingSelectors";

const mapStateToProps = state => {
	return {
		airlineCode: getFlightInboundAirlineCode(state),
		resolution: state.resolution,
	};
};

export default connect(mapStateToProps)(FidelityCardNumberInput);
