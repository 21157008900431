import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import AirlineLogo from "app/pages/.shared/AirlineLogo";
import "./ItineraryDetail.scss";
import PropTypes from "prop-types";

const ItineraryDetail = ({ legs = [], isTrainType }) => {
	const funWordId = isTrainType
		? "quotation.train.connection.fun.word.label"
		: "quotation.flight.connection.fun.word.label";

	const stopFunWordId = isTrainType ? (
		<FormattedMessage id="quotation.train.stop.fun.word.label" />
	) : (
		<FormattedMessage id="quotation.flight.stop.fun.word.label" />
	);

	const legsNode = legs.map((leg, index) => {
		const stopDurationNode = leg.to.stopDuration && (
			<div className="itinerary-detail__data itinerary-detail__data--stop-duration">
				<div className="itinerary-detail__time">{leg.to.stopDuration}</div>

				<div className="itinerary-detail__stop-fun-word">
					{leg.to.iata === legs[index + 1].from.iata ? (
						stopFunWordId
					) : (
						<FormattedMessage
							values={{
								airport: legs[index + 1].from.airport,
							}}
							id={funWordId}
						/>
					)}
				</div>
			</div>
		);

		return (
			<div key={index}>
				<ul className="itinerary-detail__list">
					<li className="itinerary-detail__item itinerary-detail__from">
						<div className="itinerary-detail__content">
							<div className="itinerary-detail__data">
								<div className="itinerary-detail__date">
									<FormattedDate
										timeZone="UTC"
										value={new Date(leg.from.takeOffDate)}
										day="2-digit"
										month="short"
									/>
								</div>
								<div className="itinerary-detail__time">{leg.from.takeOffTime}</div>
								<div className="itinerary-detail__airport">
									{leg.from.airport}
									<span className="itinerary-detail__iata">
										({leg.from.iata})
									</span>
								</div>
							</div>
						</div>
					</li>

					<li className="itinerary-detail__item itinerary-detail__item--flight-duration">
						<div className="itinerary-detail__content">
							<div className="itinerary-detail__data">
								<div className="itinerary-detail__time">
									{leg.to.flightDuration}
								</div>
								<div className="itinerary-detail__airline">
									<div className="itinerary-detail__logo">
										<AirlineLogo airlineCode={leg.airline.code} />
									</div>
									<div>
										<div>{leg.airline.name}</div>
										<div>{leg.flightNumber}</div>
										{leg.operatingAirlineName ? (
											<div className="itinerary-detail__operated-by">
												<FormattedMessage
													values={{
														airline: leg.operatingAirlineName,
													}}
													id="quotation.flight.flight.operator.label"
												/>
											</div>
										) : (
											false
										)}
									</div>
								</div>
							</div>
						</div>
					</li>

					<li className="itinerary-detail__item itinerary-detail__item--to">
						<div className="itinerary-detail__content ">
							<div className="itinerary-detail__data">
								<div className="itinerary-detail__date">
									<FormattedDate
										timeZone="UTC"
										value={new Date(leg.to.landingDate)}
										day="2-digit"
										month="short"
									/>
								</div>
								<div className="itinerary-detail__time">{leg.to.landingTime}</div>
								<div className="itinerary-detail__airport">
									{leg.to.airport}
									<span className="itinerary-detail__iata">({leg.to.iata})</span>
								</div>
							</div>
						</div>
					</li>
				</ul>

				{stopDurationNode}
			</div>
		);
	});

	return <div className="itinerary-detail">{legsNode}</div>;
};

ItineraryDetail.propTypes = {
	legs: PropTypes.arrayOf(PropTypes.object),
	isTrainType: PropTypes.bool,
};

export default ItineraryDetail;
