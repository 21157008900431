import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { resolutionPropType } from "app/utils/propTypes";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import Input from "app/pages/.shared/form/Input";
import "./FidelityCardNumberInput.scss";

const FidelityCardNumberInput = ({ airlineCode, field, id, onBlur, resolution }) => {
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const labelID = isMobile
		? "general.frequent.traveler.card.number.short"
		: "general.frequent.traveler.card.number";

	return (
		<div className="fidelity-card-number-input">
			<Input
				label={<FormattedMessage id={labelID} />}
				field={field}
				name={`${id}-airline-fidelity-logo`}
				id={`${id}-airline-fidelity-logo`}
				onBlur={onBlur}
				data-cy={`fidelity-card-number-input-${id}`}
			/>
			{airlineCode && (
				<div className="fidelity-card-number-input__logo-container">
					<img
						className="fidelity-card-number-input__logo"
						src={`https://res.cloudinary.com/perfectstay/image/upload/v1/airline-fidelity/${airlineCode}.svg`}
						alt={airlineCode}
					/>
				</div>
			)}
		</div>
	);
};

FidelityCardNumberInput.propTypes = {
	airlineCode: PropTypes.string,
	field: PropTypes.object,
	id: PropTypes.string,
	onBlur: PropTypes.func,
	resolution: resolutionPropType,
};

export default FidelityCardNumberInput;
