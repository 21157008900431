/* eslint-disable no-console */
import React from "react";
import { sendTagOnException } from "app/utils/analytics";
import SimpleHeaderContainer from "app/pages/Header/SimpleHeader/SimpleHeaderContainer";
import TechnicalErrorDefault from "app/pages/TechnicalError/default/TechnicalErrorDefault";
import FooterContainer from "app/pages/Footer/FooterContainer";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			eventId: null,
		};
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		// @see https://docs.sentry.io/platforms/javascript/react/
		Sentry.withScope(scope => {
			scope.setExtras(info);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
		sendTagOnException({ error, info });
	}

	render() {
		if (this.state.hasError) {
			return (
				<React.Fragment>
					<SimpleHeaderContainer />
					<div style={{ margin: "40px 0" }}>
						<TechnicalErrorDefault />
					</div>
					<FooterContainer />
				</React.Fragment>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
