import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { sendTagOnChangeLanguage } from "app/utils/analytics";
import { replaceShopInUrl } from "app/utils/urlDataExtractor";
import AdvancedSelect from "app/pages/.shared/form/AdvancedSelect";

class LanguageSwitcherComponent extends React.Component {
	constructor(props) {
		super(props);
		this.selectLanguage = this.selectLanguage.bind(this);
	}

	selectLanguage({ value }) {
		const { currentShop } = this.props;
		const selectedShop = value;
		const redirectURL = replaceShopInUrl(window.location.href, currentShop, selectedShop);
		window.location = redirectURL;
		sendTagOnChangeLanguage(selectedShop.slice(0, 2));
	}

	render() {
		const { shops = {}, currentShop } = this.props;

		const options = Object.keys(shops).map(shop => {
			return { value: shops[shop].code, label: shops[shop].label };
		});

		return (
			<AdvancedSelect
				placeholder={<FormattedMessage id="select.language.placeholder" />}
				options={options}
				value={options.find(({ value }) => {
					return value === currentShop;
				})}
				isSearchable={false}
				getOptionValue={({ value }) => value}
				getOptionLabel={({ label }) => label}
				onChange={this.selectLanguage}
			/>
		);
	}
}

LanguageSwitcherComponent.propTypes = {
	shops: PropTypes.object,
	currentShop: PropTypes.string,
};

export const LanguageSwitcher = withRouter(LanguageSwitcherComponent);
