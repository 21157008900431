import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import HelpDeskPhoneNumberContainer from "app/pages/.shared/HelpDeskPhoneNumber/HelpDeskPhoneNumberContainer.jsx";
import FooterLogos from "app/pages/Footer/FooterLogos/FooterLogos";
import ReassuranceContainer from "app/pages/Footer/Reassurance/ReassuranceContainer";
import ShopSwitcherContainer from "app/pages/Home/ShopSwitcher/ShopSwitcherContainer";
import SocialNetworksList from "app/pages/.shared/SocialNetworksList/SocialNetworksList";
import { LanguageSwitcher } from "app/pages/.shared/LanguageSwitcher/LanguageSwitcher";
import {
	landingPageAuthShape,
	partnerRedirectionLinkShape,
	socialNetworksLinksShape,
} from "app/utils/propTypes";
import InsuranceLink from "app/pages/Footer/InsuranceLink/InsuranceLink";
import HelpLink from "app/pages/Footer/HelpLink/HelpLink";
import PriceGuaranteeLink from "app/pages/Footer/PriceGuaranteeLink/PriceGuaranteeLink";
import NoteCasinoContainer from "app/pages/Footer/NoteCasino/NoteCasinoContainer";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

import "./Footer.scss";

const Footer = ({
	partnerRedirectionLink,
	socialNetworksLinks,
	shop,
	helpContactUrl,
	cgvDocumentName,
	legalesDocumentName,
	confidentialiteDocumentName,
	landingPageAuth,
	showReassurance,
	showShopSwitcher,
	showPfsMessage,
	useSVA,
	additionnalNote,
	additionalLink,
	feedbackLink,
	showCasinoNote,
	resolution,
	isHomePage,
}) => {
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const showSocialNetworksLinks =
		socialNetworksLinks && (socialNetworksLinks.instagram || socialNetworksLinks.facebook);

	return (
		<footer className="footer">
			<div className="footer__container">
				{/*Ne pas afficher les engagagement dans le footer de la Home*/}
				{!isHomePage && showReassurance && (
					<div className="footer__engagements">
						<ReassuranceContainer />
						<hr className="footer__separator" />
					</div>
				)}
				<div className="footer__footer">
					<div className="footer__social">
						<div className="social">
							{showPfsMessage && (
								<React.Fragment>
									<div className="footer__partner">
										<div className="footer__partner-title">
											<FormattedMessage id="footer.partner.title" />
										</div>
										<div className="footer__partner-text">
											<FormattedMessage id="footer.partner.text" />
										</div>

										<div className="footer__partner-logo">
											<i className="icon icon--logo-perfectstay-white" />
										</div>
									</div>
									<hr className="footer__separator" />
								</React.Fragment>
							)}
							{showSocialNetworksLinks && (
								<SocialNetworksList socialNetworksLinks={socialNetworksLinks} />
							)}
						</div>
					</div>

					{showSocialNetworksLinks && isMobile && <hr className="footer__separator" />}

					<div className="footer__contact">
						<div className="footer-contact">
							<div className="footer-contact__headline">
								<FormattedMessage id="footer.contact.headline" />
							</div>
							<HelpDeskPhoneNumberContainer useSVA={useSVA} />
						</div>
						<hr className="footer__separator" />

						{showShopSwitcher && (
							<div className="footer__language">
								<ShopSwitcherContainer
									renderComponent={props => <LanguageSwitcher {...props} />}
								/>
							</div>
						)}
					</div>
					<div className="footer__menu">
						<div className="footer__row">
							<ul className="footer-menu">
								{partnerRedirectionLink &&
									partnerRedirectionLink.url &&
									partnerRedirectionLink.label && (
										<li className="footer-menu__item">
											<a
												className="relative-link"
												rel="noopener noreferrer"
												target="_blank"
												href={partnerRedirectionLink.url}
											>
												{partnerRedirectionLink.label}
											</a>
										</li>
									)}
								{shop === "fr-FR" && feedbackLink && (
									<li className="footer-menu__item">
										<a
											className="relative-link"
											rel="noopener noreferrer"
											target="_blank"
											href={feedbackLink}
										>
											<FormattedMessage id="footer.feedback.label" />
										</a>
									</li>
								)}
								{cgvDocumentName && (
									<li className="footer-menu__item">
										<a
											className="relative-link"
											rel="noopener noreferrer"
											target="_blank"
											href={cgvDocumentName}
										>
											<FormattedMessage id="menu.item.cgv" />
										</a>
									</li>
								)}
								{legalesDocumentName && (
									<li className="footer-menu__item">
										<a
											className="relative-link"
											rel="noopener noreferrer"
											target="_blank"
											href={legalesDocumentName}
										>
											<FormattedMessage id="menu.item.legales" />
										</a>
									</li>
								)}
								{confidentialiteDocumentName && (
									<li className="footer-menu__item">
										<a
											className="relative-link"
											rel="noopener noreferrer"
											target="_blank"
											href={confidentialiteDocumentName}
										>
											<FormattedMessage id="menu.item.confidentialite" />
										</a>
									</li>
								)}
							</ul>
							<ul className="footer-menu">
								{shop !== "en-GB" && (
									<li className="footer-menu__item">
										<InsuranceLink />
									</li>
								)}
								{helpContactUrl && (
									<li className="footer-menu__item">
										<HelpLink helpContactUrl={helpContactUrl} />
									</li>
								)}
								<li className="footer-menu__item">
									<PriceGuaranteeLink />
								</li>
								{additionalLink}
							</ul>
						</div>

						<div className="footer__row">
							<div className="footer-pictos">
								<FooterLogos shop={shop} />
							</div>
						</div>
					</div>
				</div>

				{additionnalNote && <div className="footer__note">{additionnalNote}</div>}

				{showCasinoNote && (
					<div className="footer__note">
						<NoteCasinoContainer />
					</div>
				)}

				{landingPageAuth.note && <div className="footer__note">{landingPageAuth.note}</div>}
			</div>
		</footer>
	);
};

Footer.propTypes = {
	partnerRedirectionLink: partnerRedirectionLinkShape,
	socialNetworksLinks: socialNetworksLinksShape,
	partnerRedirectionUrlFooter: PropTypes.string,
	helpContactUrl: PropTypes.string,
	cgvDocumentName: PropTypes.string,
	legalesDocumentName: PropTypes.string,
	shop: PropTypes.string,
	confidentialiteDocumentName: PropTypes.string,
	landingPageAuth: landingPageAuthShape,
	hasCasinoNote: PropTypes.bool,
	showReassurance: PropTypes.bool,
	showShopSwitcher: PropTypes.bool,
	useSVA: PropTypes.bool,
	showPfsMessage: PropTypes.bool,
	additionnalNote: PropTypes.element,
	additionalLink: PropTypes.element,
	feedbackLink: PropTypes.string,
	isHomePage: PropTypes.bool,
	showCasinoNote: PropTypes.bool,
	resolution: PropTypes.string,
};

Footer.defaultProps = {
	landingPageAuth: {},
	additionnalNote: null,
	useSVA: false,
	showReassurance: true,
	showPfsMessage: false,
	additionalLink: null,
	showShopSwitcher: false,
};

export default React.memo(Footer);
