import React from "react";
import { sendTagOnContactCall } from "app/utils/analytics";
import "./HelpDeskPhoneNumber.scss";
import { FormattedMessage } from "react-intl";
import Separator from "app/pages/.shared/Separator";
import PropTypes from "prop-types";
import PhoneNumberSVA from "app/pages/.shared/HelpDeskPhoneNumber/PhoneNumberSVA/PhoneNumberSVA";
import RelativeLink from "../RelativeLink";
import IconPhone from "app/pages/.shared/static/icons/IconPhoneBordered";

const HelpDeskPhoneNumber = ({
	dialPhone,
	displayPhone = [],
	officeHours = [],
	displayInternationalPhone = [],
	internationalDialPhone,
	callPrice,
	useSVA,
	showFAQLink,
	helpContactUrl,
	svaVariant,
	showPicto,
}) => (
	<div className="help-desk-phone-number">
		{useSVA && svaVariant ? (
			<PhoneNumberSVA
				dialPhone={dialPhone}
				displayPhone={displayPhone}
				callPrice={callPrice}
				svaVariant={svaVariant}
			/>
		) : (
			<div className="help-desk-phone-number__number">
				{showPicto && (
					<IconPhone
						height={35}
						width={35}
						style={{
							top: "5px",
							left: "10px",
						}}
					/>
				)}
				<a
					onClick={sendTagOnContactCall}
					className="help-desk-phone-number__link"
					href={`tel:${dialPhone}`}
				>
					{displayPhone.join(" ")}
				</a>
			</div>
		)}
		<div className="help-desk-phone-number__info">
			{officeHours.map((officeHour, key) => {
				return <span key={key}>{officeHour}</span>;
			})}
			{(!useSVA || !svaVariant) && (
				<span className="help-desk-phone-number__price">{callPrice}</span>
			)}
		</div>

		{displayInternationalPhone && displayInternationalPhone.length > 0 && (
			<div className="help-desk-phone-number__international">
				<Separator design="middle" />

				<div className="help-desk-phone-number__intro">
					<FormattedMessage id="help.desk.abroad.intro" />
				</div>

				<div className="help-desk-phone-number__number">
					<a
						onClick={sendTagOnContactCall}
						className="help-desk-phone-number__link"
						href={`tel:${internationalDialPhone}`}
					>
						{displayInternationalPhone.join(" ")}
					</a>
				</div>

				<div className="help-desk-phone-number__info">
					<FormattedMessage id="help.desk.abroad.price.note" />
				</div>
			</div>
		)}

		{showFAQLink && (
			<React.Fragment>
				<Separator design="middle" />
				<div className="help-desk-phone-number__email">
					<FormattedMessage id="technical.error.registration.email.label" />
					<RelativeLink
						design="naked"
						rel="noopener noreferrer"
						target="_blank"
						to={{
							pathname: helpContactUrl,
						}}
					>
						<FormattedMessage id="technical.error.registration.email.link.label" />
					</RelativeLink>
				</div>
			</React.Fragment>
		)}
	</div>
);

HelpDeskPhoneNumber.propTypes = {
	dialPhone: PropTypes.string,
	helpContactUrl: PropTypes.string,
	showFAQLink: PropTypes.bool,
	displayPhone: PropTypes.arrayOf(PropTypes.string),
	internationalDialPhone: PropTypes.string,
	displayInternationalPhone: PropTypes.arrayOf(PropTypes.string),
	officeHours: PropTypes.arrayOf(PropTypes.string),
	callPrice: PropTypes.string,
	useSVA: PropTypes.bool,
	svaVariant: PropTypes.object,
	showPicto: PropTypes.bool,
};

export default React.memo(HelpDeskPhoneNumber);
