import { connect } from "react-redux";
import HelpDeskPhoneNumber from "app/pages/.shared/HelpDeskPhoneNumber/HelpDeskPhoneNumber";
import { withRouter } from "react-router-dom";
import { getContextCallCenter } from "app/pages/Booking/bookingSelectors";
import { getSvaVariant } from "app/pages/.shared/HelpDeskPhoneNumber/PhoneNumberSVA/phoneNumberSvaUtils";

const mapStateToProps = (state, ownProps) => {
	const isPagePayment = ownProps.match.url === "/booking/payment";
	const isQuotationPage = ownProps.match.url === "/booking/quote";
	const isConfirmationPage = ownProps.match.url === "/confirmation";
	const contextCallCenter = getContextCallCenter({
		isPaymentPage: isPagePayment,
		isQuotationPage,
		isConfirmationPage,
	})(state);

	if (ownProps.useSVA && contextCallCenter.displayPhone) {
		const firstFourDisplayPhoneDigits = contextCallCenter.displayPhone.join("").slice(0, 4);
		const svaVariant = getSvaVariant(firstFourDisplayPhoneDigits);
		return {
			svaVariant,
			...contextCallCenter,
		};
	}

	return {
		...contextCallCenter,
	};
};

export default withRouter(connect(mapStateToProps)(HelpDeskPhoneNumber));
